import {pull} from 'lodash';
import {Subject} from 'rxjs';
import {ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector} from '@angular/core';
import {map} from 'rxjs/operators';

import {
  CaptureEventListener,
  CaptureEventListeners,
  GlobalCaptureEventListeners,
} from '../../services/global-capture-event-listeners.service';
import {elementIsOutside} from '../../utils/dom/element-is-outside';
import {Point} from '../../types';

import {ElementOverlayOrigin, OverlayOrigin} from './overlay-origin';
import {
  ClickOutsideAction,
  EscapePressAction,
  OverlayAction,
  OverlayContent,
  OverlayEvent,
  OverlayInstance,
  OverlayOptions,
  OverlayPlacement,
  OverlayShowOptions,
} from './overlay.types';
import {isComponentContent, isTemplateContent} from './overlay.helpers';
import {OverlayComponent} from './overlay/overlay.component';

/**
 * Service which initializes `OverlayComponent` dynamically and attaches provided component or templateRef into it.
 * Used to show popovers, tooltips and etc dynamically.
 */
@Injectable({
  providedIn: 'root',
})
export class Overlay {
  overlays: OverlayInstance[] = [];

  constructor(
    private globalEvents: GlobalCaptureEventListeners,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
  ) {}

  show<TContentProps extends object = any, TResult = any>(
    content: OverlayContent<TContentProps>,
    opts: OverlayShowOptions<TContentProps> = {},
  ): OverlayInstance<TContentProps, TResult> {
    const overlayFactory = this.componentFactoryResolver.resolveComponentFactory(OverlayComponent);
    let overlayRef: ComponentRef<OverlayComponent>;

    if (isTemplateContent(content)) {
      overlayRef = content.viewContainerRef.createComponent(
        overlayFactory,
        undefined,
        opts.injector || content.viewContainerRef.injector,
      );
    } else if (isComponentContent(content)) {
      overlayRef = content.viewContainerRef.createComponent(
        overlayFactory,
        undefined,
        content.viewContainerRef.injector,
        undefined,
        content.envInjector,
      );
    } else {
      overlayRef = overlayFactory.create(opts.injector || this.injector);
      this.appRef.attachView(overlayRef.hostView);
    }

    const origin = opts.origin instanceof HTMLElement ? new ElementOverlayOrigin(opts.origin) : opts.origin || null;
    const options: OverlayOptions = {...opts, origin};
    const overlayContainer = this.getOverlayContainerElement(options);

    overlayRef.instance.content = content;
    overlayRef.instance.opts = options;
    overlayRef.instance.overlayContainer = overlayContainer;

    const listeners: CaptureEventListeners = {};
    const closed = new Subject<{result?: TResult; reason: OverlayEvent}>();
    const overlay: OverlayInstance<TContentProps, TResult> = {
      type: options.type,
      element: overlayRef.instance.hostElement,
      component: overlayRef.instance,
      opened: true,
      destroyed: false,
      get mobileView() {
        return overlayRef.instance.isMobileView;
      },
      closed,
      result: closed.pipe(map(({result}) => result)).toPromise(),
      get originElement(): HTMLElement | null {
        return options.origin?.element || null;
      },
      get contentComponent() {
        const overlayComponent = overlayRef.instance as OverlayComponent<TContentProps>;

        return overlayComponent.contentComponent;
      },

      hide: (result?: any, reason?: OverlayEvent) => {
        if (!overlay.destroyed) {
          overlay.destroyed = true;
          overlay.opened = false;
          overlayRef.destroy();
          overlay.closed.next({result, reason: reason ?? OverlayEvent.CUSTOM_ACTION});
          overlay.closed.complete();
          pull(this.overlays, overlay);
          this.globalEvents.remove(listeners);
        }
      },

      position: (placement?: string | OverlayPlacement) => {
        if (!overlay.destroyed) {
          // Update options for the case when position() is called before ngOnInit is called
          overlayRef.instance.opts.placement = placement;
          overlayRef.instance.position(placement);
        }
      },

      updateOrigin: newOrigin => {
        if (!overlay.destroyed) {
          // Update options for the case when updateOrigin() is called before ngOnInit is called
          const overlayOrigin: OverlayOrigin =
            newOrigin instanceof HTMLElement ? new ElementOverlayOrigin(newOrigin) : newOrigin;

          overlayRef.instance.opts.origin = overlayOrigin;
          overlayRef.instance.updateOrigin(overlayOrigin);
        }
      },

      updateContentProps: props => {
        if (!overlay.destroyed) {
          overlayRef.instance.updateContentProps(props);
        }
      },

      updatePointerOrigin: (point: Point) => {
        if (!overlay.destroyed) {
          overlayRef.instance.updatePointerOrigin(point);
        }
      },
    };

    overlayRef.instance.overlay = overlay;
    this.overlays.push(overlay);

    const {beforeShow, onEscape, onWindowResize, onClickOutside} = options;

    if (beforeShow) {
      beforeShow.emit(overlay);
    }

    if (onEscape) {
      const {action, skipOtherHandlers} = this.parseOnEscape(onEscape);

      listeners['keydown.esc'] = this.makeActionHandler(overlay, action, OverlayEvent.ESCAPE_PRESS, skipOtherHandlers);
    }

    if (onWindowResize) {
      listeners.resize = this.makeActionHandler(overlay, onWindowResize, OverlayEvent.WINDOW_RESIZE, false);
    }

    if (onClickOutside) {
      const {elements, triggerEvent, skipOtherHandlers} = this.parseOnClickOutside(onClickOutside);

      listeners[triggerEvent] = this.makeClickOutsideHandler(overlay, elements, skipOtherHandlers);
    }

    this.globalEvents.add(listeners);

    overlayContainer.appendChild(overlay.element);

    // Making overlay work in `OnPush` components
    overlayRef.hostView.detectChanges();

    return overlay;
  }

  hideAll(...types: Array<OverlayInstance['type']>) {
    this.overlays.forEach(overlay => {
      if (!types.length || types.includes(overlay.type)) {
        overlay.hide();
      }
    });
  }

  updateAllPosition() {
    this.overlays.forEach(overlay => overlay.position());
  }

  getNestedOverlayElements(overlay: OverlayInstance): HTMLElement[] {
    return this.overlays
      .filter(({originElement}) => originElement && overlay.element.contains(originElement))
      .map(({element}) => element);
  }

  private getOverlayContainerElement({attachTo, origin}: OverlayOptions): HTMLElement {
    if (attachTo === 'parent-overlay' || attachTo === 'parent-overlay-or-body') {
      if (origin) {
        const parentOverlay = this.overlays.find(overlay => overlay.component.element.contains(origin.element))
          ?.component.element;

        if (!parentOverlay && attachTo === 'parent-overlay') {
          throw new Error("Couldn't find parent overlay");
        }

        return parentOverlay || document.body;
      }

      throw new Error("For the `attachTo: 'parent-overlay'` option, `origin` must be provided");
    }

    return attachTo || document.body;
  }

  private makeActionHandler(
    overlay: OverlayInstance,
    action: OverlayAction,
    event: OverlayEvent,
    skipOtherHandlers: boolean,
  ): CaptureEventListener {
    if (action === 'position') {
      return () => overlay.position();
    }

    const height = window.innerHeight;
    const width = window.innerWidth;

    return () => {
      // skip resize event that is dispatched on mobile on soft keyboard
      if (event !== OverlayEvent.WINDOW_RESIZE || height !== window.innerHeight || width !== window.innerWidth) {
        overlay.hide(undefined, event);
      }

      return skipOtherHandlers;
    };
  }

  private makeClickOutsideHandler(
    overlay: OverlayInstance,
    elements: NonNullable<ClickOutsideAction['elements']>,
    skipOtherHandlers: ClickOutsideAction['skipOtherHandlers'],
  ): CaptureEventListener {
    return (event: MouseEvent) => {
      const additionalElements = elements().filter((element): element is HTMLElement => Boolean(element));
      // Also ignoring clicks inside other overlays which origin elements are located inside the current overlay.
      const nestedOverlayElements = this.getNestedOverlayElements(overlay);

      if (elementIsOutside(event.target as Element, overlay.element, ...additionalElements, ...nestedOverlayElements)) {
        overlay.hide(undefined, OverlayEvent.CLICK_OUTSIDE);

        return skipOtherHandlers;
      }
    };
  }

  private parseOnEscape<TContentProps extends object>(
    onEscape: NonNullable<OverlayShowOptions<TContentProps>['onEscape']>,
  ): Required<EscapePressAction> {
    let action: EscapePressAction['action'];
    let skipOtherHandlers: boolean;

    if (typeof onEscape === 'string') {
      action = onEscape;
      skipOtherHandlers = true;
    } else {
      action = onEscape.action;
      skipOtherHandlers = onEscape.skipOtherHandlers !== false;
    }

    return {action, skipOtherHandlers};
  }

  private parseOnClickOutside<TContentProps extends object>(
    onClickOutside: NonNullable<OverlayShowOptions<TContentProps>['onClickOutside']>,
  ): Required<ClickOutsideAction> {
    let action: ClickOutsideAction['action'];
    let elements: NonNullable<ClickOutsideAction['elements']>;
    let triggerEvent: ClickOutsideAction['triggerEvent'];
    let skipOtherHandlers: boolean;

    if (typeof onClickOutside === 'string') {
      action = onClickOutside;
      elements = () => [];
      triggerEvent = 'click';
      skipOtherHandlers = false;
    } else {
      action = onClickOutside.action;
      elements = onClickOutside.elements ?? (() => []);
      triggerEvent = onClickOutside.triggerEvent || 'click';
      skipOtherHandlers = Boolean(onClickOutside.skipOtherHandlers);
    }

    return {action, elements, triggerEvent, skipOtherHandlers};
  }
}
