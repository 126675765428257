import {Injectable} from '@angular/core';

import {AbstractLcapPageConfigService} from './abstract-lcap-page-config.service';
import {
  LCAP_CHART_COLORS_PALETTE,
  LCAP_WIDGET_BACKGROUND_COLOR_PALETTE,
  LCAP_WIDGET_BORDER_COLOR_PALETTE,
  LCAP_WIDGET_TEXT_COLOR_PALETTE,
} from './lcap-page-config.constants';

@Injectable()
export class LcapPageConfigService extends AbstractLcapPageConfigService {
  emptyImage = 'lcap-layout' as const;
  emptyWidgetEditorHeader = 'Build charts based on workflow apps, data tables and recipes';
  entityName = 'page';
  themingContext = 'lcap' as const;
  usesDataTables = true;
  usesAppFunctions = true;
  usesBranding = true;
  supportsConditionalVisibility = true;
  supportsImageWidget = true;
  supportsCustomPageBackgroundColors = true;
  supportsPageVariables = true;
  supportsDataTree = true;
  supportsDarkMode = false;
  chartColorsPalette = LCAP_CHART_COLORS_PALETTE;
  allowCustomColor = true;
  widgetBorderColorPalette = LCAP_WIDGET_BORDER_COLOR_PALETTE;
  widgetBackgroundColorPalette = LCAP_WIDGET_BACKGROUND_COLOR_PALETTE;
  widgetTextColorPalette = LCAP_WIDGET_TEXT_COLOR_PALETTE;
}
